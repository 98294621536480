import React from "react";

const Logo = () => (


<svg width="100" height="30" viewBox="0 0 382 132" xmlns="http://www.w3.org/2000/svg">
 <g>
  <title>background</title>
  <rect x="-1" y="-1" width="382" height="132" id="canvas_background" fill="#fff"/>
  <g id="canvasGrid" display="none">
   <rect id="svg_1" width="100%" height="100%" x="0" y="0" strokeWidth="0" fill="url(#gridpattern)"/>
  </g>
 </g>
 <g>
  <title>Layer 1</title>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="3.500622" y="95.453125" id="svg_8" fontSize="100" fontFamily="Helvetica, Arial, sans-serif" >k</text>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="47.5" y="95.453125" id="svg_9" fontSize="100" fontFamily="Helvetica, Arial, sans-serif"  fillOpacity="0.7">o</text>
  <text fill="#4f4fe2" strokeWidth="0" x="97.49917" y="95.453125" id="svg_10" fontSize="100" fontFamily="Helvetica, Arial, sans-serif"  stroke="#000">b</text>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="146.498755" y="95.453125" id="svg_11" fontSize="100" fontFamily="Helvetica, Arial, sans-serif" >y</text>
  <rect fill="#4f4fe2" strokeWidth="0" x="150.178446" y="108.913673" width="5.334816" height="7.194912" id="svg_13" transform="rotate(15.284491539001465 152.84585571289045,112.51113128662111) " stroke="#000"/>
  <path fill="#4f4fe2" stroke="#000" strokeWidth="0" opacity="0.5" d="m158.39772,115.78409" id="svg_15"/>
  <path stroke="#000" transform="rotate(20.02766227722168 148.7020874023437,110.84496307373053) " fill="#4f4fe2" strokeWidth="0" d="m151.451984,114.839582l-2.87489,-0.694032l-2.6249,-0.79173l1.99992,-6.503483l3.49987,1.017937l0,6.971307z" id="svg_16"/>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="195.58079" y="95.455151" id="svg_17" fontSize="100" fontFamily="Helvetica, Arial, sans-serif"  fillOpacity="0.7">o</text>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="245.581412" y="95.455151" id="svg_18" fontSize="100" fontFamily="Helvetica, Arial, sans-serif" >f</text>
  <text fill="#4f4fe2" strokeWidth="0" x="269.569257" y="95.455151" id="svg_19" fontSize="100" fontFamily="Helvetica, Arial, sans-serif"  stroke="#000">e</text>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="320.569257" y="95.455151" id="svg_20" fontSize="100" fontFamily="Helvetica, Arial, sans-serif" >k</text>
  <path fill="#4f4fe2" stroke="#000" strokeWidth="0" strokeOpacity="null" fillOpacity="0.75" id="svg_21"/>
  <path fill="#4f4fe2" strokeWidth="0" d="m348.5313,76.92419l2.89591,0.13742l-0.06528,7.99874l-2.87215,-0.18895" id="svg_22" stroke="#000" transform="translate(-77,-54) rotate(0.7102363705635071 349.9584655761702,80.99226379394796) "/>
  <path transform="translate(-77,-54) " fill="#4f4fe2" strokeWidth="0" d="m351.28126,77.11169l2.89591,0.13741l-0.06527,7.99875l-2.87215,-0.18895" id="svg_23" stroke="#000"/>
  <text fill="#4f4fe2" stroke="#000" strokeWidth="0" x="177.53959" y="95.457295" id="svg_24" fontSize="100" fontFamily="Helvetica, Arial, sans-serif" >.</text>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 152.42913818359395,112.8188018798831) " ry="3.38168" rx="2.797987" id="svg_2" cy="112.818799" cx="152.42913" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 151.2416534423826,111.25632476806635) " ry="3.38168" rx="2.797987" id="svg_3" cy="111.256321" cx="151.241647" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 149.49168395996128,110.38133239746092) " ry="3.38168" rx="2.797987" id="svg_4" cy="110.381333" cx="149.491671" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 147.80419921874986,111.19382476806668) " ry="3.38168" rx="2.797987" id="svg_5" cy="111.193822" cx="147.804194" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 149.11668395996136,112.06880950927736) " ry="3.38168" rx="2.797987" id="svg_6" cy="112.068809" cx="149.116676" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 150.179168701172,112.44380950927707) " ry="3.38168" rx="2.797987" id="svg_7" cy="112.443804" cx="150.179161" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 151.30415344238298,112.88130187988281) " ry="3.38168" rx="2.797987" id="svg_12" cy="112.881298" cx="151.304146" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
  <ellipse stroke="#000" transform="rotate(4.5775041580200195 148.17919921874977,110.13134002685543) " ry="3.38168" rx="2.797987" id="svg_14" cy="110.131336" cx="148.179189" fillOpacity="null" strokeOpacity="null" strokeWidth="0" fill="#4f4fe2"/>
 </g>
</svg>
);

export default Logo;
