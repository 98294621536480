/* eslint no-console:0 no-alert:0 */
import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import '../css/convertKit.css';

export default class Newsletter extends React.Component {
    state = {
        FNAME: null,
        email: null,
        response:null
    }

    _handleChange = e => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        })
        this.setState({
            [`${e.target.name}`]: e.target.value,
        })
    }

    _handleSubmit = e => {
      debugger;
        e.preventDefault()

        console.log('submit', this.state)

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                // console.log('msg', `${result}: ${msg}`)
                this.setState({response:msg});

                if (result !== 'success') {

                    this.setState({response:'there has been some error'});
                }

            })
            .catch(err => {
                console.log('err', err)
                alert(err)
            })
    }

    render() {

      if ((this.state.response!==null) && (this.state.response!=='there has been some error')) {
        var infoClass='formkit-alert formkit-alert-success';
      } else {
        infoClass='formkit-alert  formkit-alert-error';
      }
        return (
            <div>
      <form className="seva-form formkit-form"  data-sv-form="1434598" data-uid="9a61691514" data-format="inline" data-version="5" min-width="400 500 600 700 800" style={{"backgroundColor":"rgb(249, 250, 251)","borderRadius":"4px"}} onSubmit={this._handleSubmit}>
      <div className="formkit-background" style={{"opacity":"0.2"}}></div><div data-style="minimal"><div className="formkit-header" data-element="header" style={{"color":"rgb(77, 77, 77)","fontSize":"27px","fontWeight":"700"}}><h1>Let's talk about making awesome products, about productivity, about making the most out of life.</h1></div>
      <div className="formkit-subheader" data-element="subheader" style={{"color":"rgb(104, 104, 104)","fontSize":"18px"}}><p>I send some articles and musings to people. Many people find them interesting and thought provoking. Give it a shot, you probably won't regret it, and in case you do - hit the unsubscribe button.</p></div>
      {this.state.response!==null && <ul className={infoClass} data-element="info" data-group="info">{this.state.response}</ul>}
      {this.state.response==null &&
      <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields"><span style={{fontSize:"35px"}}>👉</span>
      <div className="formkit-field"><input onChange={this._handleChange} className="formkit-input" name="FNAME" placeholder="Your Name" required="" type="text" style={{"color":"rgb(0, 0, 0)","borderColor":"rgb(227, 227, 227)","borderRadius":"4px","fontWeight":"400"}} /></div>
      <div className="formkit-field"><input onChange={this._handleChange} className="formkit-input" name="email" placeholder="Your email address" required="required" type="email" style={{"color":"rgb(0, 0, 0)","borderColor":"rgb(227, 227, 227)","borderRadius":"4px","fontWeight":"400"}} /></div>
      <button type="submit" data-element="submit" className="formkit-submit formkit-submit" style={{"color":"rgb(255, 255, 255)","backgroundColor":"rgb(22, 119, 190)","borderRadius":"4px","fontWeight":"400"}}>
        <div className="formkit-spinner"></div>
        <span>Subscribe</span>
      </button>
      </div>}
      <div className="formkit-guarantee" data-element="guarantee" style={{"color":"rgb(77, 77, 77)","fontSize":"13px","fontWeight":"400"}}>I won't send you spam. Unsubscribe at any time.</div>

      </div></form>
            </div>
        )
    }
}
